import React, {useState, useEffect} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import {Toolbar} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';
import {ClimbingBoxLoader} from 'react-spinners';

import {AuthProvider} from 'context/auth';
import {useDispatch} from 'react-redux';

import {getConfigs} from 'services/api';
import {Creators as ConfigActions} from 'store/ducks/config';

import Home from '../pages/home';
import Activity from '../pages/activity';
import SignIn from '../pages/signIn';
import SignUp from '../pages/signUp';
import EditUser from '../pages/editUser';
import Profile from '../pages/profile';
import Authenticate from '../pages/authenticate';

import {LeftSidebar} from './layout-blueprints';

import {createTheme} from '../theme';
import {Interceptor} from './Interceptor';

const DefaultTheme = createTheme('#3d4977', '#5383ff', '#3d4977');

const SuspenseLoading = () => (
  <>
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <ClimbingBoxLoader color="#5383ff" loading />
      </div>
      <div className="text-muted font-size-xl text-center pt-3">Carregando</div>
    </div>
  </>
);

export const AppNavigation = () => {
  const location = useLocation();
  const [theme, setTheme] = useState(DefaultTheme);
  const [pms, setPms] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function bootstrapAsync() {
      try {
        const responseConfig = await getConfigs();
        const {primarycolor, secondarycolor, barcolor} = responseConfig.data;

        const newTheme = createTheme(primarycolor, secondarycolor, barcolor);

        setTheme(newTheme);
        setPms(responseConfig.data.pms);
        const symbol = responseConfig.data.currencysymbol;
        dispatch(ConfigActions.setConfig({symbol}));
      } catch (e) {
        // TODO
      }

      setInitialized(true);
    }
    bootstrapAsync();
  }, [dispatch]);

  if (!initialized) {
    return <SuspenseLoading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Interceptor />
        <LeftSidebar>
          <Toolbar id="back-to-top-anchor" style={{marginBottom: '-85px'}} />

          <Switch location={location} key={location.pathname}>
            <Route exact path="/:id">
              <Home />
            </Route>

            <Route exact path="/:id/schedule/activity/:idactivity">
              <Activity pms={pms} />
            </Route>

            <Route exact path="/:id/customer/signin">
              <SignIn />
            </Route>

            <Route exact path="/:id/customer/signup">
              <SignUp />
            </Route>

            <Route exact path="/:id/customer/auth">
              <Authenticate />
            </Route>

            <Route exact path="/:id/customer/profile">
              <Profile />
            </Route>

            <Route exact path="/:id/customer/account">
              <EditUser pms={pms} />
            </Route>
          </Switch>
        </LeftSidebar>
      </AuthProvider>
    </ThemeProvider>
  );
};
